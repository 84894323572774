import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ContainerEquipment } from 'app/generated/backend/types/container-equipment';
import { DateOrderStyle } from 'app/generated/system/date-time/date-order-style';
import { LineBreak } from 'app/generated/system/io/line-break';
import { PartyPlaceMasterModel } from 'app/generated/backend/yard/api/party-place-master-model';
import { PartyPlaceMasterRequestModel } from 'app/generated/backend/yard/api/party-place-master-request-model';

@Injectable()
export class PartyPlaceMasterHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	download(
		request: PartyPlaceMasterRequestModel
	): Observable<Blob> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (request.partyId!==null && request.partyId!==undefined) {
			params = params.set('partyId', request.partyId.toString());
		}
		if (request.code!==null && request.code!==undefined) {
			params = params.set('code', request.code);
		}
		if (request.name!==null && request.name!==undefined) {
			params = params.set('name', request.name);
		}
		if (request.isStore!==null && request.isStore!==undefined) {
			params = params.set('isStore', request.isStore.toString());
		}
		if (request.equipment!==null && request.equipment!==undefined) {
			params = params.set('equipment', request.equipment.toString());
		}
		if (request.downloadSettings!==null && request.downloadSettings!==undefined) {
			if (request.downloadSettings.columnSeparator!==null && request.downloadSettings.columnSeparator!==undefined) {
				params = params.set('downloadSettings.columnSeparator', request.downloadSettings.columnSeparator);
			}
			if (request.downloadSettings.decimalSeparator!==null && request.downloadSettings.decimalSeparator!==undefined) {
				params = params.set('downloadSettings.decimalSeparator', request.downloadSettings.decimalSeparator);
			}
			if (request.downloadSettings.dateOrderStyle!==null && request.downloadSettings.dateOrderStyle!==undefined) {
				params = params.set('downloadSettings.dateOrderStyle', request.downloadSettings.dateOrderStyle.toString());
			}
			if (request.downloadSettings.lineBreak!==null && request.downloadSettings.lineBreak!==undefined) {
				params = params.set('downloadSettings.lineBreak', request.downloadSettings.lineBreak.toString());
			}
			if (request.downloadSettings.fileName!==null && request.downloadSettings.fileName!==undefined) {
				params = params.set('downloadSettings.fileName', request.downloadSettings.fileName);
			}
		}
		if (request.offset!==null && request.offset!==undefined) {
			params = params.set('offset', request.offset.toString());
		}
		if (request.limit!==null && request.limit!==undefined) {
			params = params.set('limit', request.limit.toString());
		}
		if (request.sortProperties!==null && request.sortProperties!==undefined) {
			params = params.set('sortProperties', request.sortProperties.toString());
		}
		return this.http.get<Blob>('api/v1/yard/parties/places/master', { params, responseType: 'blob' as 'json', headers: {'Accept': 'application/vnd.ms-excel'} });
	}
	list(
		request: PartyPlaceMasterRequestModel
	): Observable<JsonResourceResponse<PartyPlaceMasterModel[]>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (request.partyId!==null && request.partyId!==undefined) {
			params = params.set('partyId', request.partyId.toString());
		}
		if (request.code!==null && request.code!==undefined) {
			params = params.set('code', request.code);
		}
		if (request.name!==null && request.name!==undefined) {
			params = params.set('name', request.name);
		}
		if (request.isStore!==null && request.isStore!==undefined) {
			params = params.set('isStore', request.isStore.toString());
		}
		if (request.equipment!==null && request.equipment!==undefined) {
			params = params.set('equipment', request.equipment.toString());
		}
		if (request.downloadSettings!==null && request.downloadSettings!==undefined) {
			if (request.downloadSettings.columnSeparator!==null && request.downloadSettings.columnSeparator!==undefined) {
				params = params.set('downloadSettings.columnSeparator', request.downloadSettings.columnSeparator);
			}
			if (request.downloadSettings.decimalSeparator!==null && request.downloadSettings.decimalSeparator!==undefined) {
				params = params.set('downloadSettings.decimalSeparator', request.downloadSettings.decimalSeparator);
			}
			if (request.downloadSettings.dateOrderStyle!==null && request.downloadSettings.dateOrderStyle!==undefined) {
				params = params.set('downloadSettings.dateOrderStyle', request.downloadSettings.dateOrderStyle.toString());
			}
			if (request.downloadSettings.lineBreak!==null && request.downloadSettings.lineBreak!==undefined) {
				params = params.set('downloadSettings.lineBreak', request.downloadSettings.lineBreak.toString());
			}
			if (request.downloadSettings.fileName!==null && request.downloadSettings.fileName!==undefined) {
				params = params.set('downloadSettings.fileName', request.downloadSettings.fileName);
			}
		}
		if (request.offset!==null && request.offset!==undefined) {
			params = params.set('offset', request.offset.toString());
		}
		if (request.limit!==null && request.limit!==undefined) {
			params = params.set('limit', request.limit.toString());
		}
		if (request.sortProperties!==null && request.sortProperties!==undefined) {
			params = params.set('sortProperties', request.sortProperties.toString());
		}
		return this.http.get<JsonResourceResponse<PartyPlaceMasterModel[]>>('api/v1/yard/parties/places/master', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		PartyPlaceMasterHandlerService
	]
})
export class PartyPlaceMasterHandlerModule { }
