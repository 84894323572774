import { Injectable } from '@angular/core';
import { CampaignModel } from 'app/generated/backend/crm/api/campaign-model';
import { CompanyLookupModel } from 'app/generated/backend/crm/api/company-lookup-model';
import { CurrencyLookupModel } from 'app/generated/backend/currency/api/currency-lookup-model';
import { AgreementLookupModel } from 'app/generated/backend/finance/api/agreement-lookup-model';
import { InvoiceTemplateLookupModel } from 'app/generated/backend/finance/api/invoice-template-lookup-model';
import { CountryLookupModel } from 'app/generated/backend/locality/api/country-lookup-model';
import { FacilityLookupModel } from 'app/generated/backend/locality/api/facility-lookup-model';
import { CountryTimeZoneLookupModel } from 'app/generated/backend/localization/api/country-time-zone-lookup-model';
import { LanguageLookupModel } from 'app/generated/backend/localization/api/language-lookup-model';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { CarrierLookupModel } from 'app/generated/backend/party/api/carrier-lookup-model';
import { PartyLocationLookupModel } from 'app/generated/backend/party/api/party-location-lookup-model';
import { PartyLookupModel } from 'app/generated/backend/party/api/party-lookup-model';
import { PartyPartyLookupModel } from 'app/generated/backend/party/api/party-party-lookup-model';
import { PartyPartyPartyLookupModel } from 'app/generated/backend/party/api/party-party-party-lookup-model';
import { PartyPlaceLookupModel } from 'app/generated/backend/yard/api/party-place-lookup-model';
import { PartyUserLookupModel } from 'app/generated/backend/party/api/party-user-lookup-model';
import { PurchaseOrderItemLookupModel } from 'app/generated/backend/purchasing/api/purchase-order-item-lookup-model';
import { RailCarrierLookupModel } from 'app/generated/backend/rail/api/rail-carrier-lookup-model';
import { PartyChargeLookupModel } from 'app/generated/backend/rating/api/party-charge-lookup-model';
import { PartyRateContractLookupModel } from 'app/generated/backend/contract/api/party-rate-contract-lookup-model';
import { PartySeaServiceLookupModel } from "app/generated/backend/sea/api/party-sea-service-lookup-model";
import { SeaCarrierLookupModel } from 'app/generated/backend/sea/api/sea-carrier-lookup-model';
import { ShipmentLookupModel } from 'app/generated/backend/shipment/api/shipment-lookup-model';
import { DocumentTypeLookupModel } from 'app/generated/backend/storage/api/document-type-lookup-model';
import { SupportEpicLookupModel } from 'app/generated/backend/support/api/support-epic-lookup-model';
import { TrackingDevicePartyLookupModel } from 'app/generated/backend/tracking/api/tracking-device-party-lookup-model';
import { HarmonizedCommodityLookupModel } from 'app/generated/backend/trade/api/harmonized-commodity-lookup-model';
import { HazardousSubstanceLookupModel } from 'app/generated/backend/trade/api/hazardous-substance-lookup-model';
import { IncotermsLookupModel } from 'app/generated/backend/trade/api/incoterms-lookup-model';
import { PackageTypeLookupModel } from 'app/generated/backend/trade/api/package-type-lookup-model';
import { RateIndexLookupModel } from 'app/generated/backend/trade/api/rate-index-lookup-model';
import { SeasonalityProfileLookupModel } from 'app/generated/backend/trade/api/seasonality-profile-lookup-model';
import { PermissionFlags } from 'app/generated/backend/types/permission-flags';
import { UserGroupLookupModel } from 'app/generated/backend/user/api/user-group-lookup-model';
import { UserLookupModel } from 'app/generated/backend/user/api/user-lookup-model';
import { CoreAuthenticationService } from '../authentication/CoreAuthenticationService';
import { CoreCacheShipment } from './CoreCacheShipment';
import { CoreEntityCache } from './CoreEntityCache';
import { IndustrialClassificationLookupModel } from 'app/generated/backend/trade/api/industrial-classification-lookup-model';

@Injectable()
export class CoreCacheService {
	private partyShipmentsCache = new CoreEntityCache<ShipmentLookupModel>();
	private invoiceTemplatesCache = new CoreEntityCache<InvoiceTemplateLookupModel>();
	private rateIndexesCache = new CoreEntityCache<RateIndexLookupModel>();
	private packageTypesCache = new CoreEntityCache<PackageTypeLookupModel>();
	private partyLocationsCache = new CoreEntityCache<PartyLocationLookupModel>();
	private partyChargesCache = new CoreEntityCache<PartyChargeLookupModel>();
	private partyRateContractsCache = new CoreEntityCache<PartyRateContractLookupModel>();
	private documentTypesCache = new CoreEntityCache<DocumentTypeLookupModel>();
	private partyUsersCache = new CoreEntityCache<PartyUserLookupModel>();
	private carriersCache = new CoreEntityCache<CarrierLookupModel>();
	private agreementsCache = new CoreEntityCache<AgreementLookupModel>();
	private trackingDevicePartyCache = new CoreEntityCache<TrackingDevicePartyLookupModel>();
	private seaFclCarriersCache = new CoreEntityCache<SeaCarrierLookupModel>();
	private seaLclCarriersCache = new CoreEntityCache<SeaCarrierLookupModel>();
	private seaCarriersCache = new CoreEntityCache<SeaCarrierLookupModel>();
	private railFclCarriersCache = new CoreEntityCache<RailCarrierLookupModel>();
	private railLclCarriersCache = new CoreEntityCache<RailCarrierLookupModel>();
	private facilitiesCache = new CoreEntityCache<FacilityLookupModel>();
	private partySeaServiceCache = new CoreEntityCache<PartySeaServiceLookupModel>();
	private countriesCache = new CoreEntityCache<CountryLookupModel>();
	private currenciesCache = new CoreEntityCache<CurrencyLookupModel>();
	private countryTimeZonesCache = new CoreEntityCache<CountryTimeZoneLookupModel>();
	private incotermsLookupCache = new CoreEntityCache<IncotermsLookupModel>();
	private languagesCache = new CoreEntityCache<LanguageLookupModel>();
	private localesCache = new CoreEntityCache<LocaleLookupModel>();
	private harmonizedCommoditiesCache = new CoreEntityCache<HarmonizedCommodityLookupModel>();
	private industrialClassificationsCache = new CoreEntityCache<IndustrialClassificationLookupModel>();
	private hazardousSubstancesCache = new CoreEntityCache<HazardousSubstanceLookupModel>();
	private partiesCache = new CoreEntityCache<PartyLookupModel>();
	private companiesCache = new CoreEntityCache<CompanyLookupModel>();
	private partyPartiesCache = new CoreEntityCache<PartyPartyLookupModel>();
	private partyPartyPartiesCache = new CoreEntityCache<PartyPartyPartyLookupModel>();
	private partyPlacesCache = new CoreEntityCache<PartyPlaceLookupModel>();
	private partyStoresCache = new CoreEntityCache<PartyPlaceLookupModel>();
	private userGroupsCache = new CoreEntityCache<UserGroupLookupModel>();
	private usersCache = new CoreEntityCache<UserLookupModel>();
	private seasonalityProfilesCache = new CoreEntityCache<SeasonalityProfileLookupModel>();
	private shipmentCache = new CoreCacheShipment();
	private purchaseOrderItemCache = new CoreEntityCache<PurchaseOrderItemLookupModel>();
	private campaignCache = new CoreEntityCache<CampaignModel>();
	private supportEpicCache = new CoreEntityCache<SupportEpicLookupModel>();
	private partyPermissions: PermissionFlags[];

	constructor(authenticationService: CoreAuthenticationService) {
		authenticationService.authenticationChanged.subscribe(auth => {
			this.partyLocationsCache.clear();
			this.partyChargesCache.clear();
			this.partyUsersCache.clear();
			this.partyPartyPartiesCache.clear();
			this.partyPartiesCache.clear();
			this.partyPlacesCache.clear();
			this.partyStoresCache.clear();
			this.partyRateContractsCache.clear();
			this.partyShipmentsCache.clear();
			this.purchaseOrderItemCache.clear();
			this.partySeaServiceCache.clear();
			this.supportEpicCache.clear();
			this.shipmentCache = new CoreCacheShipment();
			this.partyPermissions = auth?.party?.permissions;
		});
	}
	getPartyPermissions(): PermissionFlags[] {
		return this.partyPermissions;
	}
	getAgreementsCache(): CoreEntityCache<AgreementLookupModel> {
		return this.agreementsCache;
	}
	getSupportEpicCache(): CoreEntityCache<SupportEpicLookupModel> {
		return this.supportEpicCache;
	}
	getPartyShipmentCache(): CoreEntityCache<ShipmentLookupModel> {
		return this.partyShipmentsCache;
	}
	getShipmentCache(): CoreCacheShipment {
		return this.shipmentCache;
	}
	getInvoiceTemplateCache(): CoreEntityCache<InvoiceTemplateLookupModel> {
		return this.invoiceTemplatesCache;
	}
	getDocumentTypes(): CoreEntityCache<DocumentTypeLookupModel> {
		return this.documentTypesCache;
	}
	getRateIndexes(): CoreEntityCache<RateIndexLookupModel> {
		return this.rateIndexesCache;
	}
	getParties(): CoreEntityCache<PartyLookupModel> {
		return this.partiesCache;
	}
	getCompanies(): CoreEntityCache<CompanyLookupModel> {
		return this.companiesCache;
	}
	clearCompanies() {
		this.companiesCache.clear();
	}
	getTrackingDeviceParties(): CoreEntityCache<TrackingDevicePartyLookupModel> {
		return this.trackingDevicePartyCache;
	}
	getPartyParties(): CoreEntityCache<PartyPartyLookupModel> {
		return this.partyPartiesCache;
	}
	getUserGroups(): CoreEntityCache<UserGroupLookupModel> {
		return this.userGroupsCache;
	}
	getPackageTypes(): CoreEntityCache<PackageTypeLookupModel> {
		return this.packageTypesCache;
	}
	getPartyCharges(): CoreEntityCache<PartyChargeLookupModel> {
		return this.partyChargesCache;
	}
	getPartyRateContracts(): CoreEntityCache<PartyRateContractLookupModel> {
		return this.partyRateContractsCache;
	}
	getPartyUsers(): CoreEntityCache<PartyUserLookupModel> {
		return this.partyUsersCache;
	}
	getPartyLocations(): CoreEntityCache<PartyLocationLookupModel> {
		return this.partyLocationsCache;
	}
	getPartyPartyParties(): CoreEntityCache<PartyPartyPartyLookupModel> {
		return this.partyPartyPartiesCache;
	}
	getPartyPlaces(): CoreEntityCache<PartyPlaceLookupModel> {
		return this.partyPlacesCache;
	}
	getPartyStores(): CoreEntityCache<PartyPlaceLookupModel> {
		return this.partyStoresCache;
	}
	getFacilities(): CoreEntityCache<FacilityLookupModel> {
		return this.facilitiesCache;
	}
	getSeasonalityProfiles(): CoreEntityCache<SeasonalityProfileLookupModel> {
		return this.seasonalityProfilesCache;
	}
	getUsers(): CoreEntityCache<UserLookupModel> {
		return this.usersCache;
	}
	getHarmonizedCommodities(): CoreEntityCache<HarmonizedCommodityLookupModel> {
		return this.harmonizedCommoditiesCache;
	}
	getIndustrialClassifications(): CoreEntityCache<IndustrialClassificationLookupModel> {
		return this.industrialClassificationsCache;
	}
	getHazardousSubstances(): CoreEntityCache<HazardousSubstanceLookupModel> {
		return this.hazardousSubstancesCache;
	}
	getCarriers(): CoreEntityCache<CarrierLookupModel> {
		return this.carriersCache;
	}
	getSeaFclCarriers(): CoreEntityCache<SeaCarrierLookupModel> {
		return this.seaFclCarriersCache;
	}
	getSeaLclCarriers(): CoreEntityCache<SeaCarrierLookupModel> {
		return this.seaLclCarriersCache;
	}
	getSeaCarriers(): CoreEntityCache<SeaCarrierLookupModel> {
		return this.seaCarriersCache;
	}
	getRailFclCarriers(): CoreEntityCache<RailCarrierLookupModel> {
		return this.railFclCarriersCache;
	}
	getRailLclCarriers(): CoreEntityCache<RailCarrierLookupModel> {
		return this.railLclCarriersCache;
	}
	getPartySeaServices(): CoreEntityCache<PartySeaServiceLookupModel> {
		return this.partySeaServiceCache;
	}
	getCountries(): CoreEntityCache<CountryLookupModel> {
		return this.countriesCache;
	}
	getCountryTimeZones(): CoreEntityCache<CountryTimeZoneLookupModel> {
		return this.countryTimeZonesCache;
	}
	getCurrencies(): CoreEntityCache<CurrencyLookupModel> {
		return this.currenciesCache;
	}
	getIncotermsLookups(): CoreEntityCache<IncotermsLookupModel> {
		return this.incotermsLookupCache;
	}
	getLanguages(): CoreEntityCache<LanguageLookupModel> {
		return this.languagesCache;
	}
	getLocales(): CoreEntityCache<LocaleLookupModel> {
		return this.localesCache;
	}
	getPurchaseOrderItems(): CoreEntityCache<PurchaseOrderItemLookupModel> {
		return this.purchaseOrderItemCache;
	}
	getCampaigns(): CoreEntityCache<CampaignModel> {
		return this.campaignCache;
	}
}
