import { Injectable } from "@angular/core";
import { DownloadSettings } from "app/generated/backend/types/download-settings";
import { CoreCustomizationService } from "../customization/CoreCustomizationService";

@Injectable()
export class CoreDownloadService {

    constructor(
        private customizationService: CoreCustomizationService
    ) {
    }

    getSettings(fileName: string): DownloadSettings {

        const downloadSettings = new DownloadSettings();
        const locale = this.customizationService.getLocale();
        downloadSettings.columnSeparator = locale.columnSeparator;
        downloadSettings.decimalSeparator = locale.decimalSeparator;
        downloadSettings.dateOrderStyle = locale.dateOrderStyle;
        downloadSettings.fileName = fileName + '_' + new Date().toString() + '.csv';
        return downloadSettings;
    }

    download(downloadSettings: DownloadSettings, blob: Blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = downloadSettings.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}