<app-panel titleTranslateKey="{{isStoreLocation ? 'nav.store' : 'nav.place'}}" helpUri="help/party/place/add"
  [loading]="loading">

  <form #editForm="ngForm">
    <mat-dialog-content>

      <div class="form-group row">
        <app-party-place-lookup *ngIf="!isStoreLocation" class="col-sm-12" id="partyPlace" name="partyPlace"
          [(ngModel)]="mergePartyPlaceId" [required]="true" label="{{ 'field.mergePartyPlace.label' | translate}}">
        </app-party-place-lookup>
        <app-party-store-lookup *ngIf="isStoreLocation" class="col-sm-12" id="store" name="store"
          [(ngModel)]="mergePartyPlaceId" [required]="true" label="{{ 'field.mergePartyPlace.label' | translate}}">
        </app-party-store-lookup>
      </div>

    </mat-dialog-content>
    <div mat-dialog-actions>
      <div class="col-sm-12 adn-buttons-horizontal text-right">
        <app-button-cancel (action)="onCancel()"></app-button-cancel>
        <app-button-save (action)="onSave()" [disabled]="!editForm.valid || mergePartyPlaceId == partyPlaceId">
        </app-button-save>
      </div>
    </div>
  </form>


</app-panel>