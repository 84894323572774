import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AddressLookupModule } from 'app/core/address/AddressLookupModule';
import { ContainerEquipmentInputModule } from 'app/core/container-equipment-input/ContainerEquipmentInputModule';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CoreLocalityLookupModule } from 'app/core/locality/lookup/CoreLocalityLookupModule';
import { CoreMapModule } from 'app/core/map/CoreMapModule';
import { MasterModule } from 'app/core/master/MasterModule';
import { CoreSelectInputModule } from 'app/core/select-input/CoreSelectInputModule';
import { CoreFacilityModule } from 'app/core/facility/CoreFacilityModule';
import { CoreTextInputModule } from 'app/core/text-input/CoreTextInputModule';
import { ContainerEquipmentModule } from 'app/generated/backend/types/container-equipment';
import { GeocodingHandlerModule } from 'app/generated/backend/locality/service/geocoding-handler';
import { ReverseGeocodingHandlerModule } from 'app/generated/backend/locality/service/reverse-geocoding-handler';
import { PartyPlaceDetailHandlerModule } from 'app/generated/backend/yard/service/party-place-detail-handler';
import { PartyPlaceMasterHandlerModule } from 'app/generated/backend/yard/service/party-place-master-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreButtonModule } from '../../core/button/CoreButtonModule';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { CoreCountryModule } from '../../core/country/CoreCountryModule';
import { PanelModule } from '../../core/panel/PanelModule';
import { PartyDetailModule } from '../footer/PartyDetailModule';
import { PartyPlaceLookupComponent } from './PartyPlaceLookupComponent';
import { PartyPlaceLookupService } from './PartyPlaceLookupService';
import { PartyPlaceMasterComponent } from './PartyPlaceMasterComponent';
import { WeekdaysModule } from 'app/core/weekdays/WeekdaysModule';
import { PartyStoreLookupComponent } from 'app/party/place/PartyStoreLookupComponent';
import { PartyStoreLookupService } from 'app/party/place/PartyStoreLookupService';
import { CorePartyStoreCodePipe } from 'app/party/place/detail/CorePartyStoreCodePipe';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CoreDownloadService } from 'app/core/download/CoreDownloadService';


@NgModule({
	imports: [
		AddressLookupModule,
		AutocompleteModule,
		CommonMaterialModule,
		CommonModule,
		ContainerEquipmentInputModule,
		ContainerEquipmentModule,
		CoreButtonModule,
		CoreCountryModule,
		CoreLocalityLookupModule,
		CoreMapModule,
		CoreSelectInputModule,
		CoreFacilityModule,
		CoreTextInputModule,
		DragDropModule,
		FormsModule,
		GeocodingHandlerModule,
		HelpPopupModule,
		MasterModule,
		MatSortModule,
		MatTableModule,
		NgxPaginationModule,
		PanelModule,
		PartyDetailModule,
		PartyPlaceDetailHandlerModule,
		PartyPlaceMasterHandlerModule,
		ReverseGeocodingHandlerModule,
		TranslateModule,
		WeekdaysModule
	],
	exports: [PartyPlaceMasterComponent, PartyPlaceLookupComponent, PartyStoreLookupComponent, CorePartyStoreCodePipe],
	declarations: [PartyPlaceMasterComponent, PartyPlaceLookupComponent, PartyStoreLookupComponent, CorePartyStoreCodePipe],
	providers: [CoreDownloadService, PartyPlaceLookupService, PartyStoreLookupService]
})
export class PartyPlaceModule { }
