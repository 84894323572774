import { MapsAPILoader } from '@agm/core';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CoreCustomizationService } from 'app/core/customization/CoreCustomizationService';
import { CoreMapCoordinates } from 'app/core/map/CoreMapCoordinates';
import { GeocodingHandlerService } from 'app/generated/backend/locality/service/geocoding-handler';
import { ReverseGeocodingHandlerService } from 'app/generated/backend/locality/service/reverse-geocoding-handler';
import { OperatingHours } from 'app/generated/backend/types/operating-hours';
import { PartyAccessControl } from 'app/generated/backend/types/party-access-control';
import { PartyPlaceDetailModel } from 'app/generated/backend/yard/api/party-place-detail-model';
import { PartyPlacePointDetailModel } from 'app/generated/backend/yard/api/party-place-point-detail-model';
import { PartyPlaceDetailHandlerService } from 'app/generated/backend/yard/service/party-place-detail-handler';
import { PartyPlaceMergeDialog } from 'app/party/place/detail/PartyPlaceMergeDialog';
import { PartyDetailBase } from '../../_templates/PartyDetailBase';
import { Feature } from 'app/generated/backend/types/feature';

class PartyPlacePointDetailModelExtended extends PartyPlacePointDetailModel {
	iconUrl: string;
}


@Component({
	selector: 'app-party-place-detail',
	templateUrl: './PartyPlaceDetailComponent.html'
})
export class PartyPlaceDetailComponent extends PartyDetailBase<PartyPlaceDetailModel> implements OnInit {


	@Input()
	public isStore: boolean = false;

	@Input()
	public addFromLookup: boolean = false;

	public zoom = 2;
	private tryGetCoordinate: number = 0;
	public partyPlacePoints: PartyPlacePointDetailModelExtended[] = [];
	public activatedRoute: ActivatedRoute;
	public columns: PartyPlacePointDetailModelExtended[];
	public disabledStore: boolean = false;
	public isDestination: boolean = false;

	constructor(
		protected service: PartyPlaceDetailHandlerService,
		public customizationService: CoreCustomizationService,
		protected mapsAPILoader: MapsAPILoader,
		protected reverseGeocodingService: ReverseGeocodingHandlerService,
		protected geoCodingService: GeocodingHandlerService,
		protected dialog: MatDialog,
		injector: Injector
	) {
		super(service, PartyAccessControl.Places, 'party/places', PartyPlaceDetailModel, injector);
		this.activatedRoute = injector.get(ActivatedRoute);
	}

	onDragPlaceEnd(coordinates: CoreMapCoordinates) {
		this.getCoordinate(coordinates.latitude, coordinates.longitude);
	}

	// return address from coordinates
	getCoordinate(latitude: number, longitude: number) {
		if (this.tryGetCoordinate > 3) {
			this.nullifyAddress();
			return;
		}
		this.tryGetCoordinate++;
		this.reverseGeocodingService.get(latitude, longitude).subscribe(response => {
			this.tryGetCoordinate = 0;
			if (!this.editItem.intlAddress || this.editItem.intlAddress === this.editItem.mapAddress) {
				this.editItem.intlAddress = response.data.address;
			}
			if (!this.editItem.localAddress || this.editItem.localAddress === this.editItem.mapAddress) {
				this.editItem.localAddress = response.data.address;
			}
			this.zoom = 17;
			this.editItem.latitude = response.data.latitude;
			this.editItem.longitude = response.data.longitude;
			this.editItem.postalCode = response.data.postalCode;
			this.editItem.countryId = response.data.countryId;
			this.editItem.mapAddress = response.data.address;
			this.editItem.nearestLocality = response.data.nearestLocality;
		}, error => {
			this.alertService.error(error);
		});
	}
	togglePlaceOperatingHours() {
		if (this.editItem.placeOperatingHours) {
			this.editItem.placeOperatingHours = null;
		} else {
			this.editItem.placeOperatingHours = new OperatingHours();
			if (this.editItem.partyOperatingHours) {
				this.editItem.placeOperatingHours.countryTimeZoneId = this.editItem.partyOperatingHours.countryTimeZoneId;
				this.editItem.placeOperatingHours.startTime = this.editItem.partyOperatingHours.startTime;
				this.editItem.placeOperatingHours.endTime = this.editItem.partyOperatingHours.endTime;
				this.editItem.placeOperatingHours.days = this.editItem.partyOperatingHours.days;
			}
		}
	}
	// search address button
	onLookupIntlAddress() {
		this.geoCodingService.get(this.editItem.intlAddress).subscribe(response => {
			this.zoom = 17;
			this.editItem.latitude = response.data.latitude;
			this.editItem.longitude = response.data.longitude;
			this.editItem.postalCode = response.data.postalCode;
			this.editItem.countryId = response.data.countryId;
			this.editItem.mapAddress = response.data.address;
			this.editItem.nearestLocality = response.data.nearestLocality;
		}, error => {
			this.alertService.error(error);
		});
	}

	moveUp(index: number) {
		this.swap(index, index - 1)
	}
	moveDown(index: number) {
		this.swap(index, index + 1)
	}
	private swap(x: any, y: any) {
		var b = this.partyPlacePoints[x];
		this.partyPlacePoints[x] = this.partyPlacePoints[y];
		this.partyPlacePoints[y] = b;
	}

	onCopyIntlAddress() {
		this.editItem.intlAddress = this.editItem.mapAddress;
	}

	setDefaultMap() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				if (position.coords.latitude && position.coords.longitude) {
					this.editItem.latitude = position.coords.latitude;
					this.editItem.longitude = position.coords.longitude;
					this.zoom = 17;
					this.getCoordinate(this.editItem.latitude, this.editItem.longitude)
				} else {
					this.nullifyAddress();
				}
			}, error => {
				this.nullifyAddress();
			});
		} else {
			this.nullifyAddress();
		}

	}

	nullifyAddress() {
		this.editItem.latitude = 0;
		this.editItem.longitude = 0;
		this.editItem.mapAddress = null;
		this.zoom = 2;
	}

	ngOnInit() {
		super.ngOnInit();
		this.disabledStore = true;
		if (!this.itemId) {
			this.setDefaultMap();
		} else {
			this.zoom = 17;
		}
		if (this.isStore) {
			this.editItem.isStoreLocation = true;
		}
		if (!this.addFromLookup && !this.editItem.id) {
			this.disabledStore = false;
		}
		this.isDestination = this.authenticationService.hasFeature(Feature.Destination);
	}

	onAddPartyPlacePoint() {
		const newPartyPlacePoint = new PartyPlacePointDetailModelExtended();
		if (this.editItem.latitude && this.editItem.longitude) {
			newPartyPlacePoint.latitude = this.editItem.latitude;
			newPartyPlacePoint.longitude = this.editItem.longitude;
		}
		if (!this.partyPlacePoints) {
			this.partyPlacePoints = [];
		}
		let newCode = 1;
		while (this.validateNewCode(newCode)) {
			if (newCode === 100) {
				break;
			}
			newCode++;
		}
		newPartyPlacePoint.code = newCode.toString();
		this.partyPlacePoints.push(newPartyPlacePoint);
		this.loadIconUrl();
		this.columns = this.partyPlacePoints;
	}

	validateNewCode(newCode: number): boolean {
		let flag = false;
		this.partyPlacePoints.forEach(partyPlacePoint => {
			if (partyPlacePoint) {
				if (newCode.toString() === partyPlacePoint.code) {
					flag = true;
				}
			}
		});
		return flag;
	}

	onDragPointEnd(partyPlacePoint: PartyPlacePointDetailModelExtended, coordinates: CoreMapCoordinates) {
		partyPlacePoint.latitude = coordinates.latitude;
		partyPlacePoint.longitude = coordinates.longitude;
	}

	onDeletePartyPlacePoint(index: number) {
		const oldPartyPlacePoint = JSON.parse(JSON.stringify(this.partyPlacePoints));
		this.partyPlacePoints = [];
		for (let i = 0; i < oldPartyPlacePoint.length; i++) {
			if (i !== index) {
				this.partyPlacePoints.push(oldPartyPlacePoint[i]);
			}
		}
		this.columns = this.partyPlacePoints;
	}

	save() {
		this.editItem.partyPlacePoints = [];
		let sortOrder = 1;
		this.partyPlacePoints.forEach(partyPlacePoint => {
			const model = new PartyPlacePointDetailModel();
			model.id = partyPlacePoint.id;
			model.name = partyPlacePoint.name;
			model.code = partyPlacePoint.code;
			model.equipment = partyPlacePoint.equipment;
			model.longitude = partyPlacePoint.longitude;
			model.latitude = partyPlacePoint.latitude;
			model.sortOrder = sortOrder;
			this.editItem.partyPlacePoints.push(model);
			sortOrder++;
		});
		if (this.editItem.partyPlacePoints.length > 0) {
			this.editItem.equipment = this.editItem.partyPlacePoints[0].equipment;
		}
		this.onSave();
	}

	onLoaded() {
		this.onMapLoaded();
		this.isDestination = this.authenticationService.hasFeature(Feature.Destination);
	}


	onMapLoaded() {
		this.partyPlacePoints = []
		if (this.editItem.partyPlacePoints) {
			this.editItem.partyPlacePoints.forEach(partyPlacePoint => {
				const partyPlacePointExtended = new PartyPlacePointDetailModelExtended();
				partyPlacePointExtended.id = partyPlacePoint.id;
				partyPlacePointExtended.name = partyPlacePoint.name;
				partyPlacePointExtended.code = partyPlacePoint.code;
				partyPlacePointExtended.equipment = partyPlacePoint.equipment;
				partyPlacePointExtended.longitude = partyPlacePoint.longitude;
				partyPlacePointExtended.latitude = partyPlacePoint.latitude;
				this.partyPlacePoints.push(partyPlacePointExtended);
			});
		}
		this.loadIconUrl();
	}

	loadIconUrl() {
		this.partyPlacePoints.forEach(partyPlacePoint => {
			partyPlacePoint.iconUrl = 'api/v1/content/label?t=';
			if (partyPlacePoint.code === '') {
				partyPlacePoint.iconUrl += 'unnamed';
			} else {
				partyPlacePoint.iconUrl += partyPlacePoint.code;
			}
			partyPlacePoint.iconUrl += '&fg=000&bg=0F0';
		});
	}

	itemIdentity(index) {
		return index;
	}
	onMerge() {

		const dialogRef = this.dialog.open(PartyPlaceMergeDialog, {
			data: {
				partyPlaceId: this.editItem.id,
				isStoreLocation: this.editItem.isStoreLocation
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			this.loadData();
		});
	}
}
